import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "react-tooltip";

/**
 *
 * @param props - The root object
 * @param props.data - The root object
 * @param props.tooltipStructure - The root object
 * @param props.xyz - The root object
 * @param props.meta - The root object
 * @example
 */
export default function MetaTooltip({ meta }) {
  const dataForTooltip = (meta === undefined)
    ? ""
    : `Besteht aus ${meta.total} Datenpunkten: <br>Errechnet aus ${meta.units.used} von ${meta.units.total} erfassten Objekten`;

  return (
    (meta === undefined)
      ? null
      : (
        <div>
          <div
            data-tooltip-html={dataForTooltip}
            data-tooltip-id="tooltip-meta"
            tabIndex="-1"
          >
            <InformationCircleIcon
              className="size-5 text-gray-500 hover:text-gray-600 focus:outline-none"
              tabIndex="-1"
            />
          </div>

          <Tooltip
            className="z-50"
            delayShow={500}
            id="tooltip-meta"
            place="bottom"
          />
        </div>
      )
  );
}
