/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable import/max-dependencies */

import { InformationCircleIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import {
  useEffect,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import {
  deleteFilterQueries,
  updateQuery,
  updateQueryFromTemp
} from "~/src/modules/query-helper.js";
import { parseActiveFilters } from "~/src/modules/search-filter-helper.js";

import ProjectFilterTags from "~/src/features/filter-tags/project-filter-tags/index.jsx";
import RadiusDropdown from "~/src/features/radius-dropdown/index.jsx";
import RegionFilterModal from "~/src/features/region-filter-modal/index.jsx";
import SearchFilterModals from "~/src/features/search-filter-modals/index.jsx";

import useFilterModals from "~/src/hooks/use-filter-modals.js";
import useMediaQuery from "~/src/hooks/use-media-query.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useStore from "~/src/hooks/use-store.js";

import FilterButton from "~/src/ui/buttons/filter-button/index.jsx";
import CollapsibleContainer from "~/src/ui/containers/collapsible-container.jsx";
import AddressSelect from "~/src/ui/selects/address-select/index.jsx";
import CompanySelect from "~/src/ui/selects/company-select/index.jsx";
import ProjectSelect from "~/src/ui/selects/project-select/index.jsx";

import EsgCertificatesFilterModal from "../esg-certificate-filter-modal/index.jsx";
import LegendBox from "../legend-box/index.jsx";

/**
 *
 * @param props - The root object
 * @param props.className - The root object
 * @example
 */
const ProjectsFiltersTile = ({ className = "hidden lg:flex" }) => {
  const { query, setQuery } = useProjectQueryParams();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const isHybrid = useMediaQuery("(max-width: 1380px)");

  const setUserClickedFilterTileCollapse = useStore(
    (state) => state.setUserClickedFilterTileCollapse
  );

  const filterTileCollapsed = useStore((state) => state.filterTileCollapsed);
  const setFilterTileCollapsed = useStore((state) => state.setFilterTileCollapsed);
  const miniListTileCollapsed = useStore((state) => state.miniListTileCollapsed);

  useEffect(() => {
    if (!filterTileCollapsed && !miniListTileCollapsed && isHybrid) {
      setFilterTileCollapsed(true);
    }
  }, [miniListTileCollapsed]);

  const [automated, setAutomated] = useState(false);

  const getActiveFilter = () => parseActiveFilters(query);

  const { pathname } = useLocation();

  const queryAutomated = (typeof query.automated === "boolean")
    ? query.automated
    : false;

  const onAutomatedChange = (currentAutomated) => {
    setQuery({
      automated: currentAutomated,
      page: 1
    });
  };

  const onBBoxChange = (checked) => {
    setQuery({
      activeBBox: Boolean(checked)
    });
  };

  useEffect(() => {
    setAutomated(queryAutomated);
  }, [queryAutomated]);

  const [temporaryQuery, setTemporaryQuery] = useState();

  const filterModals = useFilterModals();

  const handleAutomatedCheckbox = ({ target: { checked } }) => {
    onAutomatedChange(checked);
  };

  const handleBBoxCheckbox = ({ target: { checked } }) => {
    onBBoxChange(checked);
  };

  const updateTemporaryQuery = (key, value) => {
    const updatedQuery = {
      ...temporaryQuery,
      [key]: value
    };

    setTemporaryQuery(updatedQuery);
  };

  const handleSubmitQuery = () => {
    const updatedQuery = updateQueryFromTemp(query, temporaryQuery);

    setQuery(updatedQuery);
    setTemporaryQuery({});
  };

  const cancelRegionFilter = () => {
    const updatedQuery = {
      ...temporaryQuery
    };

    delete updatedQuery.region;
    setTemporaryQuery(updatedQuery);
  };

  const cancelEsgCertificatesFilter = () => {
    const updatedQuery = {
      ...temporaryQuery
    };

    delete updatedQuery.certificates;
    setTemporaryQuery(updatedQuery);
  };

  const cancelSearchFilter = () => {
    const updatedQuery = {
      ...temporaryQuery
    };

    delete updatedQuery.finish_year;
    delete updatedQuery.construction_phase;
    delete updatedQuery.marketing_phase;
    delete updatedQuery.rechtsform;
    delete updatedQuery.quantity;
    delete updatedQuery.foerderung;
    delete updatedQuery.construction_type;
    delete updatedQuery.polygon;
    setTemporaryQuery(updatedQuery);
  };

  const handleDirectSubmit = (filter, value) => {
    const updatedQuery = updateQuery(query, filter, value);

    setQuery(updatedQuery, "replace");

    if (![
      "direction",
      "page",
      "sort"
    ].includes(filter)) {
      // handleAllChecked(true)
    }
  };

  const handleRadiusChange = (radius) => {
    setQuery({ radius });
  };

  const handleClearFilters = () => {
    const updatedQuery = deleteFilterQueries(query);

    setQuery(updatedQuery, "replace");
  };

  // only use this if filter tile should be collapsed on laptop by default
  // useEffect(() => {
  //   if (!userClickedFilterTileCollapse) {
  //     setFilterTileCollapsed(isLaptop);
  //   }
  // }, [isLaptop]);

  return (
    <CollapsibleContainer
      active={!isMobile}
      collapsed={isMobile ? false : filterTileCollapsed}
      collapsedClassName="h-full w-0"
      position="right"
      className={cn(
        "h-full shrink-0",
        {
          "bg-white p-3.5 border border-zinc-200 rounded w-[280px]": !isMobile
        },
        className
      )}
      onChange={(collapsed) => {
        setFilterTileCollapsed(collapsed);

        setUserClickedFilterTileCollapse(true);
      }}
      tooltipTexts={{
        collapsed: "Filter anzeigen",
        expanded: "Filter ausblenden"
      }}
    >
      <RegionFilterModal
        active={filterModals.regionFilterModal}
        cancelRegionFilter={cancelRegionFilter}
        hide={() => filterModals.setRegionFilterModal(false)}
        queryRegion={query.region}
        submit={handleSubmitQuery}
        tempQuery={temporaryQuery}
        update={updateTemporaryQuery}
      />

      <EsgCertificatesFilterModal
        active={filterModals.esgCertificatesFilterModal}
        cancelEsgFilter={cancelEsgCertificatesFilter}
        hide={() => filterModals.setEsgCertificatesFilterModal(false)}
        queryEsg={query.certificates}
        submit={handleSubmitQuery}
        tempQuery={temporaryQuery}
        update={updateTemporaryQuery}
      />

      <SearchFilterModals
        cancelSearchFilter={cancelSearchFilter}
        filterModals={filterModals}
        query={query}
        submit={handleSubmitQuery}
        tempQuery={temporaryQuery ?? {}}
        update={updateTemporaryQuery}
      />

      <div className="flex size-full justify-items-stretch text-sm" id="filters-tile-flex-container">
        <div className="flex size-full flex-col items-center gap-2">
          <div className="w-full">
            <ProjectSelect handleUpdate={handleDirectSubmit} />
          </div>

          <div className="w-full">
            <CompanySelect company={temporaryQuery?.company} handleUpdate={handleDirectSubmit} />
          </div>

          <div className="relative flex w-full flex-col justify-center ">
            <AddressSelect handleUpdate={handleDirectSubmit} street={temporaryQuery?.street} />

            {(query?.locationType === "street_address") && (
              <RadiusDropdown
                className="absolute right-3"
                onChange={handleRadiusChange}
                radius={query.radius || 2}
              />
            )}
          </div>

          <div className="mt-2 flex w-full flex-col justify-center gap-5">
            <div className="grid w-full grid-cols-2 gap-4">
              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setRegionFilterModal(true)} title="Region" />

              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setFinishYearModal(true)} title="Fertigstellung" />

              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setProjectPhaseModal(true)} title="Projektphase" />

              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setQuantityModal(true)} title="Projektgröße" />

              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setRechtsformModal(true)} title="Eigentum/Miete" />

              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setFundingModal(true)} title="Förderung" />

              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setTransactionModal(true)} title="Liegenschaft" />

              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setConstructionTypeModal(true)} title="Bautyp" />

              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setObjectTypeModal(true)} title="Objektart" />

              <FilterButton customWidth="w-full px-1" fontSize="text-xs" onClick={() => filterModals.setEsgCertificatesFilterModal(true)} title="Gebäudezertifikat" />
            </div>
          </div>

          <div className="mt-2 flex w-full items-center justify-between gap-2">
            <div className="flex items-start">
              <label className="flex cursor-pointer items-center gap-1" htmlFor="automated">
                <span>Historische Projekte anzeigen</span>
              </label>

              <div>
                <div
                  data-tooltip-html="Historische Projekte kennzeichnen sich durch mindestens drei<br>Transaktionen desselben Unternehmens innerhalb einer Einlage<br>bis zum Release von „IMMOdeveloper“ (2021)."
                  data-tooltip-id="tooltip-historic-projects"
                  tabIndex="-1"
                >
                  <InformationCircleIcon
                    className="-mt-1 size-4 text-gray-500 hover:text-gray-600 focus:outline-none"
                    tabIndex="-1"
                  />
                </div>

                <Tooltip
                  className="z-50"
                  delayShow={500}
                  id="tooltip-historic-projects"
                  place="top"
                />
              </div>

            </div>

            <input
              checked={typeof automated === "boolean" ? automated : false}
              className="size-4 cursor-pointer rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
              id="automated"
              name="automated"
              onChange={handleAutomatedCheckbox}
              type="checkbox"
            />

          </div>

          {
            isMobile
              ? null
              : (
                <div className="mt-2 flex w-full items-center justify-between gap-2">
                  <div className="flex items-start">
                    <label className="flex cursor-pointer items-center gap-1" htmlFor="bbox">
                      <span>Betrachtungsansicht aktivieren</span>
                    </label>

                    <div>
                      <div
                        data-tooltip-html="Die Betrachtungsansicht ermöglicht es, die Projektauswahl auf die aktuelle Kartenperspektive zu beschränken."
                        data-tooltip-id="tooltip-bbox-view"
                        tabIndex="-1"
                      >
                        <InformationCircleIcon
                          className="-mt-1 size-4 text-gray-500 hover:text-gray-600 focus:outline-none"
                          tabIndex="-1"
                        />
                      </div>

                      <Tooltip
                        className="z-50"
                        delayShow={500}
                        id="tooltip-bbox-view"
                        place="top"
                      />
                    </div>
                  </div>

                  <input
                    checked={query.activeBBox}
                    className="size-4 cursor-pointer rounded border border-gray-400 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0"
                    id="bbox"
                    name="bbox"
                    onChange={handleBBoxCheckbox}
                    type="checkbox"
                  />

                </div>
              )
          }

          <div className="mt-2 w-full overflow-y-auto" id="filter-tags-container">
            <ProjectFilterTags filterModals={filterModals} query={query} submit={handleDirectSubmit} />
          </div>

          {getActiveFilter().length > 0 && (
            <button className="flex-non ml-0.5 flex self-start text-xs font-semibold tracking-tight text-primary hover:underline focus:outline-none " onClick={handleClearFilters} type="button">
              Filter zurücksetzen
            </button>
          )}

          <div className="flex-1" />

          {
            ["/", "/karte"].includes(pathname) && (
              <LegendBox className="sticky mt-2 hidden h-[152px] w-full !pl-0 lg:flex" />
            )
          }
        </div>
      </div>
    </CollapsibleContainer>
  );
};

export default ProjectsFiltersTile;
