import useProject from "~/src/hooks/use-project.js";
import useProjectQueryParams from "~/src/hooks/use-project-query-params.js";
import useProjectsMap from "~/src/hooks/use-projects-map.js";
import useStore from "~/src/hooks/use-store.js";
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import {
  useEffect, useRef, useState
} from "react";

import { smoothZoomAndPan } from "./_common/_exports.js";
import { austriaBorderGeoJson } from "./austria-border-geo.js";
import { Popup, createMarkers } from "./container/_exports.js";
import { mapstyles } from "./mapstyles.js";

const centerOfAustriaLatitude = 47.650_731_457_039_42;
const centerOfAustriaLongitude = 13.245_450_349_650_449;

const centerOfAustria = {
  lat: centerOfAustriaLatitude,
  lng: centerOfAustriaLongitude
};

const defaultZoom = 8;

const defaultMapViewState = {
  zoom: defaultZoom,
  ...centerOfAustria
};

/**
 *
 * @param props0 - The root object.
 * @param props0.setIsStreetView - The root object.
 * @param props0.isSimple - The root object.
 * @param props0.projectSlugs - The root object.
 * @param props0.searchLocation - The root object.
 * @param props0.automated - The root object.
 * @example
 */
const GoogleMapsContainer = ({
  automated, isSimple, projectSlugs, searchLocation, setIsStreetView
}) => {
  const mapReference = useRef();
  const streetViewReference = useRef();

  const [map, setMap] = useState(null);

  const { query, setQuery } = useProjectQueryParams();
  const [currentPoints, setCurrentPoints] = useState([]);
  const [previousPoints, setPreviousPoints] = useState([]);

  const [queryChanged, setQueryChanged] = useState(false);

  const clusterState = useStore((state) => state.clusterState);
  const setClusterState = useStore((state) => state.setClusterState);

  const autoZoomed = useStore((state) => state.autoZoomed);
  const setAutoZoomed = useStore((state) => state.setAutoZoomed);

  const previousQuery = useStore((state) => state.prevQuery);
  const setPreviousQuery = useStore((state) => state.setPrevQuery);

  const [activeMarkers, setActiveMarkers] = useState([]);

  const [activeProject, setActiveProject] = useState(null);
  const [activePopup, setActivePopup] = useState(null);

  const mapViewState = useStore((state) => state.mapViewState);
  const setMapViewState = useStore((state) => state.setMapViewState);

  const [currentZoom, setCurrentZoom] = useState(mapViewState.zoom);
  const [currentCenter, setCurrentCenter] = useState({
    lat: mapViewState.lat,
    lng: mapViewState.lng
  });

  const selectionLoading = useStore((state) => state.selectionLoading);
  const setSelectionLoading = useStore((state) => state.setSelectionLoading);

  const selectionMutate = useStore((state) => state.selectionMutate);
  const setSelectionMutate = useStore((state) => state.setSelectionMutate);

  const setCurrentSelectionStatus = useStore((state) => state.setCurrentSelectionStatus);

  const mainSelectionType = useStore((state) => state.mainSelectionType);
  const setMainSelectionType = useStore((state) => state.setMainSelectionType);

  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  /**
   *
   * @example
   */
  const getCheckboxStateFromActiveProject = () => {
    const index = currentPoints.findIndex((point) => point.properties.slug === activeProject);

    if (index !== -1) {
      const { selected } = currentPoints[index].properties;
      const { unselected } = currentPoints[index].properties;

      if (mainSelectionType === "addAll") {
        return (!unselected);
      }
      else if (mainSelectionType === "removeAll") {
        return (Boolean(selected));
      }
    }

    return true;
  };

  const [currentSelected, setCurrentSelected] = useState(getCheckboxStateFromActiveProject());

  const setChange = useStore((state) => state.setChange);

  const {
    bounds,
    clusterExpansionZooms,
    grid,
    isLoading,
    leaves,
    mutate,
    points,
    selectionStatus,
    selectionType
  } = useProjectsMap({
    query: {
      ...query,
      // eslint-disable-next-line camelcase
      cluster_state: clusterState,
      isSimple
    }
  });

  useEffect(() => {
    if (selectionMutate) {
      /**
       *
       * @example
       */
      const mutating = async () => {
        await mutate();
        setSelectionMutate(false);
      };

      mutating();
    }
  }, [selectionMutate]);

  useEffect(() => {
    if (selectionStatus) {
      setCurrentSelectionStatus(selectionStatus);
    }
  }, [selectionStatus]);

  useEffect(() => {
    if (selectionType) {
      setMainSelectionType(selectionType);
    }
  }, [selectionType]);

  const {
    error, isLoading: isLoadingActiveProject, mutate: mutateActiveProject, project
  } = useProject(activeProject);

  /**
   *
   * @param currentMap
   * @example
   */
  const updateClusterState = (currentMap) => {
    const currentBounds = currentMap.getBounds();
    const boundingBoxString = [
      currentBounds.getSouthWest().lng(),
      currentBounds.getSouthWest().lat(),
      currentBounds.getNorthEast().lng(),
      currentBounds.getNorthEast().lat()
    ].join(",");
    const zoom = currentMap.getZoom();

    setClusterState(`${boundingBoxString}_${zoom}`);
  };

  /**
   *
   * @param checked
   * @param projectSlug
   * @example
   */
  const handleCheckbox = (checked, projectSlug) => {
    if (!selectionLoading) {
      const updateType = (checked)
        ? "add"
        : "remove";

      setCurrentSelected(checked);
      setCheckboxDisabled(true);

      setChange({
        data: [projectSlug],
        source: "map",
        type: updateType
      });
    }
  };

  useEffect(() => {
    if (isSimple) {
      setCurrentSelected(true);
    }
    else {
      setCurrentSelected(getCheckboxStateFromActiveProject());
    }
  }, [activeProject]);

  const loadingTimeout = 250;

  useEffect(() => {
    /**
     *
     * @example
     */
    const settingCheckboxDisabled = () => {
      const timeoutId = setTimeout(() => {
        setCheckboxDisabled(false);
      }, loadingTimeout);

      return () => {
        clearTimeout(timeoutId);
      };
    };

    if (checkboxDisabled && !selectionLoading) {
      settingCheckboxDisabled();
    }
  }, [selectionLoading]);

  /**
   *
   * @param points
   * @example
   */
  const transformPoints = function (points) {
    return points.map((point) => {
      if (point.id) {
        return point;
      }

      point.id = point.properties.slug;

      return point;
    });
  };

  useEffect(() => {
    if (!isLoading) {
      setPreviousPoints(currentPoints);
    }
  }, [points]);

  useEffect(() => {
    if (points) {
      const pointsTransformed = transformPoints(points);

      setCurrentPoints(pointsTransformed);
      if (selectionLoading) {
        setSelectionLoading(false);
      }
    }
  }, [previousPoints]);

  useEffect(() => {
    if (!isSimple && map) {
      setMapViewState({
        lat: currentCenter.lat,
        lng: currentCenter.lng,
        zoom: currentZoom
      });

      const currentBounds = map.getBounds();
      const boundingBoxString = [
        currentBounds.getSouthWest().lng(),
        currentBounds.getSouthWest().lat(),
        currentBounds.getNorthEast().lng(),
        currentBounds.getNorthEast().lat()
      ].join(",");

      setQuery({
        bBox: boundingBoxString,
        centerLat: currentCenter.lat,
        centerLng: currentCenter.lng,
        mapZoom: Math.round(currentZoom)
      });
    }
  }, [JSON.stringify(currentCenter), currentZoom]);

  const mapOptions = {
    center: currentCenter,
    zoom: currentZoom,

    /*
    restriction: {
      latLngBounds: {
        north: 49.0364,
        south: 46.372,
        west: 9.5308,
        east: 17.1607
      },
      strictBounds: false
    },
    */
    mapTypeControl: false,
    styles: mapstyles
  };

  const maxZoomLevel = 18;

  useEffect(() => {
    if (searchLocation !== null && map !== null) {
      map.setZoom(maxZoomLevel);
      map.panTo({
        lat: searchLocation.coords.lat,
        lng: searchLocation.coords.lng
      });
    }
  }, [searchLocation]);

  useEffect(() => {
    if (query) {
      const {
        activeBBox,
        bBox,
        centerLat,
        centerLng,
        mapZoom,
        ...cleanQuery
      } = query;

      if (JSON.stringify(cleanQuery) !== JSON.stringify(previousQuery)) {
        if (!queryChanged && !activeBBox) {
          setQueryChanged(true);
        }

        setPreviousQuery(cleanQuery);
      }
    }
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (map && queryChanged) {
      map.setZoom(defaultMapViewState.zoom);
      map.panTo({
        lat: defaultMapViewState.lat,
        lng: defaultMapViewState.lng
      });

      setAutoZoomed(false);
      setQueryChanged(false);
    }
  }, [queryChanged]);

  useEffect(() => {
    if (previousPoints.length > 0 && currentPoints.length > 0) {
      if (JSON.stringify(previousPoints) !== JSON.stringify(currentPoints)) {
        const addPoints = [];
        const removePoints = [];
        const newMarkers = [];

        let removePopup = true;

        for (const currentPoint of currentPoints) {
          if (!previousPoints.some((previousPoint) => previousPoint.id === currentPoint.id)) {
            addPoints.push(currentPoint.id);
          }

          if (currentPoint.properties.slug === activeProject) {
            removePopup = false;
          }
        }

        for (const previousPoint of previousPoints) {
          if (!currentPoints.some((currentPoint) => currentPoint.id === previousPoint.id)) {
            removePoints.push(previousPoint.id);
          }
        }

        // TODO[2025-01-01]: ACTIVE MARKER GETS PUSHED TO NEWMARKERS TWICE
        for (const activeMarker of activeMarkers) {
          if (removePoints.includes(activeMarker.id)) {
            activeMarker.setMap(null);
          }
          else {
            newMarkers.push(activeMarker);
          }
        }

        for (const point of currentPoints) {
          if (
            addPoints.includes(point.id) &&
            !newMarkers.some((marker) => marker.id === point.id)
          ) {
            const marker = createMarkers({
              activePopup,
              activeProject,
              clusterExpansionZooms,
              currentZoom,
              isSimple,
              mainSelectionType,
              map,
              point,
              setActivePopup,
              setActiveProject
            });

            newMarkers.push(marker);
          }
        }

        if (activePopup && removePopup) {
          activePopup.setMap(null);
          setActivePopup(null);
          setActiveProject(null);
        }

        setActiveMarkers(newMarkers);
      }
    }
    else if (currentPoints.length > 0) {
      for (const active of activeMarkers) {
        active.setMap(null);
      }

      const newMarkers = currentPoints.map((point) => createMarkers({
        activePopup,
        activeProject,
        clusterExpansionZooms,
        currentZoom,
        isSimple,
        mainSelectionType,
        map,
        point,
        setActivePopup,
        setActiveProject
      }));

      setActiveMarkers(newMarkers);
    }
    else if (currentPoints.length === 0) {
      for (const active of activeMarkers) {
        active.setMap(null);
      }
    }
  }, [currentPoints, activeProject]);

  const defaultMinZoom = 7;
  const defaultMaxZoom = 18;

  useEffect(() => {
    if (activeMarkers.length === 1 && !autoZoomed) {
      const newZoom = clusterExpansionZooms?.[activeMarkers[0]?.id] || defaultMinZoom;

      smoothZoomAndPan(map, newZoom, currentZoom, activeMarkers[0]);
      setAutoZoomed(true);
    }
    else if (activeMarkers.length === 1 && isSimple) {
      const newZoom = (Object.keys(clusterExpansionZooms).length > 0)
        ? clusterExpansionZooms[activeMarkers[0]?.id] || defaultMinZoom
        : defaultMaxZoom;

      const newCenter = activeMarkers[0].getPosition();

      map.panTo(newCenter);
      map.setZoom(newZoom);
    }
  }, [activeMarkers, autoZoomed]);

  useEffect(() => {
    if (isSimple) {
      setQuery(
        {
          automated,
          slugs: projectSlugs
        }
      );
    }

    const currentMap = new globalThis.google.maps.Map(mapReference.current, {
      ...mapOptions,
      mapTypeId: "hybrid"
    });

    globalThis.google.maps.event.addListener(currentMap, "idle", () => {
      setCurrentZoom(currentMap.getZoom());

      const center = currentMap.getCenter();

      setCurrentCenter({
        lat: center.lat(),
        lng: center.lng()
      });

      updateClusterState(currentMap);
    });

    const streetview = currentMap.getStreetView();

    globalThis.google.maps.event.addListener(streetview, "visible_changed", () => {
      setIsStreetView(streetview.visible);
    });

    // window.google.maps.event.addListener(map, "click", () => {
    //   setActiveProject(null);
    // });

    const countryBorderData = new globalThis.google.maps.Data();

    const opacity = 0.8;

    countryBorderData.addGeoJson(austriaBorderGeoJson);
    countryBorderData.setStyle({
      fillColor: "gray",
      fillOpacity: opacity,
      strokeColor: "red",
      strokeWeight: 1
    });
    countryBorderData.setMap(currentMap);

    setMap(currentMap);
  }, []);

  return (
    <>
      <div className="absolute" id="contentParent">
        <div
          className="w-[280px]"
          id="content"
        >
          {(project && !isSimple) ? (
            <>
              <Popup
                {...{
                  activePopup,
                  checkboxDisabled,
                  handleCheckbox,
                  map: mapReference,
                  project,
                  selected: currentSelected,
                  setActivePopup,
                  setActiveProject
                }}
              />

              {/* <div className="absolute w-full h-full bg-gray-50" onClick={() => {
                  setActiveProject(null)
                }}/> */}
            </>
          ) : null}
        </div>
      </div>

      <div className="size-full" id="google-map" ref={mapReference} />

      <div className="size-full" ref={streetViewReference} />
    </>
  );
};

export default GoogleMapsContainer;
